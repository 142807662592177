import { Link } from 'gatsby'
import React from 'react'

export default function TextLink({ className, lineClassName, title, url = '#', target}) {
  const Tag = url.length > 0 && url !== '#' ? Link : 'button';
  return (
    <Tag to={url} target={target} className={`group overflow-hidden py-1 text-current uppercase font-heading font-bold tracking-wide transition-colors duration-200 ease-in-out inline-block text-sm relative text-link ${className}`}>
      <div>{title}</div>
    </Tag>
  )
}
