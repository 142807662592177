import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout';
import StoryPreview from '~/components/molecules/StoryPreview'

export default function Category({ data, pageContext }) {
  const { previousPagePath, nextPagePath } = pageContext;
  const { wpCategory, allWpPost } = data;
  return (
    <Layout showResourcesNav headerBgWhite>
      <div className="pt-48 container">
        <h1 className="text-h1">{wpCategory.name}</h1>
      </div>

      <div className="py-20 md:py-32 md:pt-12 container">
          <div className="grid grid-cols-3 gap-8 w-full">
            {allWpPost.nodes.map((item, i) => (
              <StoryPreview key={item?.id+i} {...item} className="w-full" />
            ))}
          </div>

        {/* <div className="grid grid-cols-2 gap-8">

          {allWpPost.nodes.map((post) => (
            <Link to={post.uri} key={post.id}>
              <h3>{post.title}</h3>
            </Link>
          ))}
        </div> */}

        {/* Pagination */}
        <div className="mt-12 flex space-x-5">
          {previousPagePath.length > 0 && (
            <Link to={previousPagePath} className="btn">
              Previous Page
            </Link>
          )}
          {nextPagePath.length > 0 && (
            <Link to={nextPagePath} className="btn">
              Next Page
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogCategoryQuery($id: String!, $skip: Int!, $limit: Int!) {
    allWpPost(filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }, skip: $skip, limit: $limit) {
      nodes {
        ...PostPreview
      }
    }

    wpCategory(id: { eq: $id }) {
      id
      slug
      name
    }
  }
`;
